.dots-loader {
    display: flex;
    justify-content: center;
}

.dots-loader > div {
    width: 8px;
    height: 8px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: dots-loader 0.6s infinite alternate;
}

@keyframes dots-loader {
    to {
        opacity: 0.1;
        transform: translateY(-16px);
    }
}

.dots-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

.dots-loader > div:nth-child(3) {
    animation-delay: 0.4s;
}
