#root {
    height: 100vh;
    display: flex;
    overscroll-behavior-y: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* exclude Button with href="..." rendered as <a> */
a:not(.MuiButton-root),
a:not(.MuiButton-root):hover,
a:not(.MuiButton-root):visited,
a:not(.MuiButton-root):active {
    color: inherit;
    text-decoration: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

tbody tr:last-child > * {
    border-bottom: none;
}

th:hover {
    overflow: visible;
}

.MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    margin-left: 0 !important; /* overriding material-ui */
}

#proceduresPanel {
    height: 100%;
}

/*---- DROPZONE DIALOG WINDOW  ----*/
.MuiDropzoneArea-root {
    border-color: #ffbe45 !important;
    min-height: unset !important;
    height: 100%;
}

.MuiDropzonePreviewList-imageContainer svg {
    color: #ffbe45;
}

.MuiDropzonePreviewList-imageContainer svg:hover {
    color: #ec9200;
}

.attachmentDialog .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root button:first-child {
    background-color: #323d4c;
    color: #fcfcfc;
}

.attachmentDialog
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogActions-root
    button:first-child:hover {
    background-color: #202d40;
}

.attachmentDialog .MuiDialog-container .MuiDialog-paper .MuiDialogActions-root button:nth-child(2) {
    background-color: #ffbe45;
}

.attachmentDialog
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogActions-root
    button:nth-child(2):hover {
    background-color: #ec9200;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.dropzone-preview-container {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    padding: 0 !important;
    margin: 0 !important;
}

.dropzone-preview-item {
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    flex-basis: 100% !important;
    margin: 0 !important;
    display: block;
}

.MuiDropzonePreviewList-image {
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
}

/*.MuiGrid-root {*/
/*    overscroll-behavior-y: none;*/
/*    border: 10px solid red;*/
/*}*/

.MuiOutlinedInput-root {
    border-radius: 20px !important;
}

/*---- GOOGLE MAP  ----*/
.pac-container.pac-logo {
    z-index: 9999;
}

.gm-fullscreen-control {
    top: unset !important;
    bottom: 146px;
}

.gm-svpc {
    /* streetview btn */
    top: 26px !important;
}

@media screen and (max-width: 959px) {
    #proceduresPanel {
        height: calc(100vh - 64px - 300px);
    }
}
