.rbc-event:hover .rbc-event-content {
    white-space: initial;
}

.rbc-event:focus .rbc-event-content {
    white-space: initial;
}

.rbc-toolbar .rbc-active {
    background-color: #ffbf45 !important;
}

.rbc-toolbar button {
    background-color: transparent !important;
}

.rbc-toolbar button:hover {
    background-color: #ffbf45 !important;
}

.rbc-off-range-bg {
    background-color: rgba(247, 247, 247, 0.5) !important;
}
.rbc-off-range .rbc-button-link {
    color: #404040 !important;
}
.rbc-today {
    background-color: rgba(255, 191, 69, 0.5) !important;
}

.rbc-now .rbc-button-link {
    color: black !important;
}
.rbc-event-allday {
    background-color: #ffbf45 !important;
    color: #404040 !important;
}
